import { NgFor, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { DatePipesModule } from '@core/shared/util';

import { ExportItem } from '../../models';

@Component({
  selector: 'mpcm-export-items',
  standalone: true,
  templateUrl: './export-items.component.html',
  styleUrl: './export-items.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgTemplateOutlet, MatButtonModule, MatIconModule, DatePipesModule],
})
export class ExportItemsComponent {
  @HostBinding() readonly class = 'mpcm-export-items';

  @Input() exportItems: ExportItem[] = [];

  @Output() readonly itemDelete: EventEmitter<string> = new EventEmitter<string>();

  @ContentChild(TemplateRef)
  itemInfoTemplate!: TemplateRef<unknown>;

  deleteExportItem(articleExportId: string): void {
    this.itemDelete.emit(articleExportId);
  }

  trackByFn(_index: number, item: ExportItem): string {
    return item.articleExportId;
  }
}
