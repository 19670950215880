<table class="export-items-table">
  <tr class="export-items-table__row">
    <th class="export-items-table__header export-items-table__type-header">Typ</th>
    <th class="export-items-table__header export-items-table__name-header">Name</th>
    <th class="export-items-table__header export-items-table__user-header">Benutzer</th>
    <th class="export-items-table__header export-items-table__date-header">Exportdatum</th>
    <th class="export-items-table__header export-items-table__actions-header"></th>
  </tr>
  <tr class="export-items-table__row" *ngFor="let exportItem of exportItems; trackBy: trackByFn">
    <td colspan="2" class="export-items-table__cell export-items-table__panel-cell">
      <ng-template
        [ngTemplateOutlet]="itemInfoTemplate"
        [ngTemplateOutletContext]="{ $implicit: exportItem }"
      ></ng-template>
    </td>
    <td class="export-items-table__cell export-items-table__user-cell">
      {{ exportItem.creator.name }}
    </td>
    <td class="export-items-table__cell">
      <span [title]="exportItem.createdAt">
        {{ exportItem.createdAt | humanizeRelativeDate : { whenInRange: { days: 1 } } }}
      </span>
    </td>
    <td class="export-items-table__cell export-items-table__actions-cell">
      <button mat-button (click)="deleteExportItem(exportItem.articleExportId)">
        <mat-icon>close</mat-icon>
      </button>
    </td>
  </tr>
</table>
